import React from 'react';
import PropTypes from 'prop-types';
import { Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { ThemeProvider } from 'styled-components';
import { ThemeProvider as VeneerThemeProvider, useTheme } from '@veneer/theme';
import { RootProvider } from '@jarvis/react-portal-addons';
import Routes from './routes';
import { mergeThemeWithEnvConfigInfo } from './theme-provider';
import resources from './assets/locale/index';
import useProgramInfosApiCall from './hooks/useProgramInfosApiCall';
import { getSmartProEntitlement } from './utils/entitlements';

export const history = createBrowserHistory();

const App = props => {
  const {
    environmentConfig = null,
    stack = null,
    subscriptionData = null,
    shell,
  } = props;

  const localization = props.shell?.v1?.localization || {};
  const authProvider = props?.shell?.v1?.authProvider;
  const programInfosApiCall = useProgramInfosApiCall({
    authProvider,
    stack,
  });
  const programInfos = programInfosApiCall?.data;
  const subscriptionSmartProInfo = getSmartProEntitlement(programInfos);

  const veneerTheme = useTheme();
  const theme = {
    ...mergeThemeWithEnvConfigInfo(environmentConfig),
    ...veneerTheme,
  };

  return (
    <RootProvider
      localization={localization}
      resources={resources}
      shell={shell}
      stack={stack}
    >
      <VeneerThemeProvider>
        <ThemeProvider theme={theme}>
          <Router history={history}>
            <Routes
              {...props}
              subscriptionData={subscriptionData}
              subscriptionSmartProInfo={subscriptionSmartProInfo}
            />
          </Router>
        </ThemeProvider>
      </VeneerThemeProvider>
    </RootProvider>
  );
};

App.propTypes = {
  environmentConfig: PropTypes.objectOf(PropTypes.any),
  stack: PropTypes.number,
  shell: PropTypes.objectOf(PropTypes.any),
  subscriptionData: PropTypes.objectOf(PropTypes.any),
};

App.defaultProps = {
  environmentConfig: null,
  shell: {},
  stack: 1,
  subscriptionData: null,
};

export default App;
